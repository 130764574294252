// Generated by Framer (16060ad)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {z79g9eXa6: {hover: true}};

const cycleOrder = ["z79g9eXa6"];

const variantClassNames = {z79g9eXa6: "framer-v-10jxnhq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "z79g9eXa6", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "z79g9eXa6", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-bd6fp", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-10jxnhq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"z79g9eXa6"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"z79g9eXa6-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 875, intrinsicWidth: 700, pixelHeight: 875, pixelWidth: 700, src: new URL("assets/gi8HzwzXaFV93CLMCYO5L3d2JM.png", import.meta.url).href, srcSet: `${new URL("assets/512/gi8HzwzXaFV93CLMCYO5L3d2JM.png", import.meta.url).href} 409w, ${new URL("assets/gi8HzwzXaFV93CLMCYO5L3d2JM.png", import.meta.url).href} 700w`}} className={"framer-asf2oz"} data-framer-name={"team_bruno"} layoutDependency={layoutDependency} layoutId={"nVrVXcWSj"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-bd6fp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bd6fp .framer-1dl4twe { display: block; }", ".framer-bd6fp .framer-10jxnhq { height: 438px; overflow: hidden; position: relative; width: 350px; }", ".framer-bd6fp .framer-asf2oz { aspect-ratio: 0.8 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 438px); left: 0px; overflow: visible; position: absolute; top: 0px; width: 100%; }", ".framer-bd6fp .framer-v-10jxnhq .framer-10jxnhq { cursor: pointer; }", ".framer-bd6fp.framer-v-10jxnhq.hover .framer-asf2oz { height: var(--framer-aspect-ratio-supported, 625px); left: -75px; right: -75px; top: -94px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 438
 * @framerIntrinsicWidth 350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rY7wPoqo2":{"layout":["fixed","fixed"]}}}
 */
const FramerWuLkVocHd: React.ComponentType<Props> = withCSS(Component, css, "framer-bd6fp") as typeof Component;
export default FramerWuLkVocHd;

FramerWuLkVocHd.displayName = "team-bruno";

FramerWuLkVocHd.defaultProps = {height: 438, width: 350};

addFonts(FramerWuLkVocHd, [])